import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '@wilson/wilsonng';
import { Observable, tap } from 'rxjs';
import { MyClassesService } from 'src/app/services/my-classes.service';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import GetMyClassesResponse = Wilson.GetMyClassesResponse;
import FocusClass = Wilson.FocusClass;
import { UserService } from 'src/app/services/user.service';
import { InitialLoadService } from '../../../services/initial-load.service';

@Component({
  templateUrl: './my-classes.component.html',
  styleUrls: ['./my-classes.component.scss'],
})
export class MyClassesComponent implements OnInit {
  years: { label: string; value: number }[];

  myClasses: GetMyClassesResponse;
  focusClass: FocusClass[];
  focusYear: number;
  isPersonalTenant: boolean;

  constructor(
    private myClassesService: MyClassesService,
    private spinner: SpinnerService,
    private userService: UserService,
    private initialLoadService: InitialLoadService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isPersonalTenant = this.userService.user['isPersonalTenant'];
    this.spinner.show();
    this.getMyClasses().subscribe();
    mixpanel.track('Go To MyClasses');
  }

  changeFocusYear(event: { value }): void {
    this.updateFocusClass(event.value);
    mixpanel.track('School Year Selected', { schoolYear: event.value });
  }

  getMyClasses(): Observable<GetMyClassesResponse> {
    return this.myClassesService.getMyClassesResponse().pipe(
      tap((myClasses: GetMyClassesResponse) => {
        this.myClasses = myClasses;

        let years = [...new Set(myClasses.classes.map((c) => c.year))]
          .sort((a, b) => a - b)
          .map((year) => ({ label: `${year}-${year + 1}`, value: year }));

        // Load classes for the school year closest to the current school year.
        if (years.length) {
          const currentDate = new Date(
            this.initialLoadService.initialLoad.currentDate
          );
          const currentSchoolYear = currentDate.getFullYear() - +(currentDate.getMonth() < 6);

          years = [
            ...years.filter(y => y.value >= currentSchoolYear),
            ...years.filter(y => y.value < currentSchoolYear)
          ];

          this.focusYear = years[0].value;
          this.updateFocusClass(this.focusYear);
        }

        this.years = years.sort((a, b) => a.value - b.value);

        this.spinner.hide();
      })
    );
  }

  updateFocusClass(selectedYear: number): void {
    this.focusClass = this.myClasses.classes.filter(
      (myClass) => myClass.year === selectedYear
    );
  }

  goToClass(classId: string): void {
    this.router.navigate(['/utt/class', classId]);
  }
}
