<div class="flex flex-column md:flex-row justify-content-between">
  <div>
    <h1>My Classes</h1>
    <p class="p-text-secondary mb-3">
      View and manage your Fundations classes.
    </p>
  </div>
  <div
    *ngIf="myClasses?.classes?.length"
    class="flex align-items-stretch gap-2 h-100"
  >
    <div class="d-flex flex-nowrap align-items-center">
      <label id="yearFilter">School Year:</label>
      <p-dropdown
        (onChange)="changeFocusYear($event)"
        [(ngModel)]="focusYear"
        [options]="years"
        optionLabel="label"
        optionValue="value"
        styleClass="ms-1"
        [attr.aria-labelledby]="yearFilter"
      ></p-dropdown>
    </div>
    <a
      *ngIf="isPersonalTenant"
      [routerLink]="['new']"
      class="p-button-primary p-button-outlined"
      data-automationId="new_class_link"
      icon="pi pi-plus"
      label="New Class"
      pButton
    ></a>
  </div>
</div>
<div *ngIf="focusClass?.length" class="row align-items-start mt-4">
  <div
    *ngFor="let class of focusClass"
    class="col-xl-4 col-md-6 col-sm-6 col-xs-12 flex align-self-stretch"
  >
    <div
      [routerLink]="['/utt/class', class.id]"
      class="card w-100 p-3 fundations-{{
        class.level | lowercase
      }} d-flex flex-column justify-content-between"
      role="link"
      [attr.aria-label]="'Open ' + class.name"
      (keydown.enter)="goToClass(class.id)"
    >
      <div class="d-flex flex-nowrap justify-content-between">
        <div class="d-flex flex-column mb-3">
          <h3 class="m-0" data-automationId="class_name">{{ class.name }}</h3>
          <span class="p-text-secondary"
            >{{ class.year }}-{{ class.year + 1 }}</span
          >
        </div>
        <p-avatar
          [label]="class.level | uppercase"
          aria-hidden="true"
          shape="circle"
          size="large"
          styleClass="ms-3 program-icon"
        ></p-avatar>
      </div>
      <div class="d-flex align-items-center flex-wrap">
        <ng-container *ngIf="class.hasMetrics; else noMetrics">
          <p-tag
            data-automationId="unit_score_tag"
            [rounded]="true"
            icon="pi pi-folder"
            styleClass="metric-tag tag-info"
            value="Last Unit: {{ class.progress.lastUnit }}"
          ></p-tag>
          <p-tag
            data-automationId="benchmark_score_tag"
            [rounded]="true"
            [styleClass]="
              class.progress.overBenchmark >= 0.5
                ? 'metric-tag tag-success'
                : class.progress.overBenchmark >= 0.25
                ? 'metric-tag tag-warning'
                : 'metric-tag tag-danger'
            "
            icon="pi pi-chart-line"
            value="{{ class.progress.overBenchmark | percent }} Over Benchmark"
          ></p-tag>
          <p-tag
            data-automationId="average_score_tag"
            [rounded]="true"
            [styleClass]="
              class.progress.average >= 80
                ? 'metric-tag tag-success'
                : class.progress.average >= 70
                ? 'metric-tag tag-warning'
                : 'metric-tag tag-danger'
            "
            icon="pi pi-filter"
            value="Average: {{ class.progress.average }}"
          ></p-tag>
        </ng-container>

        <ng-template #noMetrics>
          <p class="p-text-secondary"><em>No metrics available.</em></p>
        </ng-template>
      </div>
      <p class="ms-auto mt-3 open-class" data-automationId="open_roster_link">
        Open Class &raquo;
      </p>
    </div>
  </div>
</div>
<app-empty-roster
  *ngIf="myClasses && !myClasses.classes.length"
></app-empty-roster>
