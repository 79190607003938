<ng-container *ngIf="classOverview">
  <form [formGroup]="editForm" class="flex flex-row">
    <div class="flex-column">
      <ng-container *ngIf="!isEditing">
        <h1 *ngIf="!isEditing" class="mb-2">{{ classOverview?.name }}</h1>
        <p-button
          (click)="handleEdit($event, true)"
          icon="pi pi-pencil"
          styleClass="p-button-secondary p-button-text ml-1"
          *ngIf="!classOverview.readonly && isPersonalTenant"
        ></p-button>
        <p class="p-text-secondary">
          {{ classOverview?.startingYear }}-{{
            classOverview?.startingYear + 1
          }}
        </p>
      </ng-container>
      <ng-container *ngIf="isEditing">
        <div class="d-flex align-items-center">
          <label for="className" class="me-1">Class Name:</label>
          <input
            [style]="{ width: '30rem' }"
            formControlName="name"
            id="className"
            pInputText
            placeholder="Class Name"
            type="text"
          />
          <p-button
            (click)="handleEdit($event, false)"
            [icon]="editForm.pristine ? 'pi pi-times' : 'pi pi-save'"
            [label]="editForm.pristine ? 'Cancel' : 'Save'"
            styleClass="p-button-primary ml-1"
          ></p-button>
        </div>
        <small
          *ngIf="
            editForm.controls['name'].dirty && editForm.controls['name'].invalid
          "
          class="text-danger"
          ><em
            >This required field can only contain alphanumeric characters.</em
          ></small
        >
        <br />
        <div class="d-flex align-items-center">
          <label for="classYear" class="me-1">Class Year:</label>
          <p-dropdown
            [options]="years"
            [style]="{ width: '15rem' }"
            formControlName="year"
            id="classYear"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>
      </ng-container>
    </div>
  </form>

  <h3 class="mb-0">Class Overview & Overall Performance</h3>
  <p class="p-text-secondary">
    On this page you can view the students assigned to this classroom, review
    their Fundations test scores, and assign new unit tests.
  </p>

  <div class="card card-cluster">
    <div class="card">
      <ng-container
        *ngIf="classOverview.unitScores.datasets.length; else noChartData"
      >
        <h4 class="text-center pb-2">Average Score By Unit</h4>
        <p-chart
          (onDataSelect)="onChartDataSelect($event.element.index)"
          [data]="classOverview.unitScores"
          [height]="300"
          [options]="options"
          data-automationId="my_class_average_score"
          type="bar"
          alt="A visual representation of the average score by unit within this class."
        ></p-chart>
        <p class="p-text-secondary text-center mt-3 mb-0">
          <em
            >Click on a dataset to view a unit-specific report for this
            class.</em
          >
        </p>
      </ng-container>
      <ng-template #noChartData>
        <p class="text-center p-text-secondary">
          <em
            >Add test scores in the table below to view average class scores for
            each unit.</em
          >
        </p>
      </ng-template>
    </div>

    <div class="card">
      <dx-data-grid
        #datagrid
        (onContentReady)="onContentReady()"
        [dataSource]="store"
        [rowAlternationEnabled]="true"
        [showBorders]="true"
        data-automationId="my_class_unit_test_scores"
      >
        <dxo-editing
          [allowAdding]="!classOverview.readonly && isPersonalTenant"
          [allowDeleting]="!classOverview.readonly && isPersonalTenant"
          [allowUpdating]="!classOverview.readonly && isPersonalTenant"
          mode="form"
        >
          <dxo-form colCount="2" labelLocation="top">
            <dxi-item
              itemType="group"
              caption="Edit Student Details"
              colCount="2"
            >
              <dxi-item dataField="firstName">
                <dxi-validation-rule
                  type="required"
                  message="First name is required."
                ></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="lastName">
                <dxi-validation-rule
                  type="required"
                  message="Last name is required."
                ></dxi-validation-rule>
              </dxi-item>
            </dxi-item>
            <dxi-item
              *ngIf="
                classOverview.level != WilsonProgram.FundationsCommunityLevelK
              "
            >
              <small class="text-secondary">
                This change may affect this student's
                <span class="fw-bold">FUN HUB Practice (FHP)</span>
                password.
              </small>
            </dxi-item>
          </dxo-form>
          <dxo-texts
            confirmDeleteMessage="Are you sure you wish to remove this student from this class?"
          ></dxo-texts>
        </dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-state-storing
          storageKey="dx_storage_class_roster"
          type="localStorage"
        ></dxo-state-storing>
        <dxo-toolbar>
          <dxi-item location="before" name="addRowButton">
            <div *dxTemplate>
              <p-button
                *ngIf="!classOverview.readonly && isPersonalTenant"
                (onClick)="addStudentRow()"
                icon="pi pi-user"
                label="Add New Student"
                styleClass="addStudentButton p-button-outlined mr-2"
                data-automationId="add_new_student_button"
              ></p-button>
            </div>
          </dxi-item>
          <dxi-item location="after" name="exportButton">
            <p-splitButton
              (onClick)="exportToXlsx()"
              icon="pi pi-file-excel"
              label="Export all data to Excel"
              styleClass="addStudentButton p-button-outlined mr-2"
              [model]="exportCsvOptions"
              [menuStyle]="{ width: 'auto' }"
              data-automationId="export_roster_button"
              expandAriaLabel="Export Raw CSV"
            ></p-splitButton>
          </dxi-item>
        </dxo-toolbar>

        <dxi-column
          caption="Name"
          cellTemplate="studentNameTemplate"
          minWidth="150"
          sortOrder="asc"
          [calculateCellValue]="getStudentName"
          [editorOptions]="{ maxLength: 75 }"
        ></dxi-column>

        <dxi-column
          caption="First Name"
          dataField="firstName"
          [visible]="false"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column caption="Last Name" dataField="lastName" [visible]="false">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column
          *ngFor="let def of testDefinitions"
          [caption]="def.shortName"
          [sortingMethod]="sortUnitTestColumn"
          alignment="center"
          headerCellTemplate="unitScoreHeaderTemplate"
          cellTemplate="unitScoreTemplate"
          dataField="{{ def.id }}"
        >
          <div *dxTemplate="let info of 'unitScoreHeaderTemplate'">
            <div>{{ info.column.caption }}</div>
            <div>
              <button
                role="link"
                class="btn btn-link mt-2 p-0"
                (click)="btnViewDetails($event, info.column.dataField)"
                trackClick="UTTViewDetails"
                data-automationId="unit_test_view_details"
                [attr.aria-label]="'View a summary of ' + info.column.caption"
              >
                View Details
              </button>
            </div>
          </div>
          <div *dxTemplate="let row of 'unitScoreTemplate'">
            <div class="flex justify-content-between">
              <span *ngIf="row.value.highestScoreAchieved !== null" class="m-0">
                <span
                  *ngIf="row.value.scoreAttempts.length > 1; else singleScore"
                >
                  <button
                    (click)="
                      toggleScoresModal(
                        getStudentName(row.data),
                        row.data.id,
                        row.value.scoreAttempts
                      )
                    "
                    class="wlt-btn btn-link"
                  >
                    {{ row.value.highestScoreAchieved }}*
                  </button>
                </span>
                <ng-template #singleScore>
                  {{ row.value.highestScoreAchieved }}
                </ng-template>
              </span>

              <a
                *ngIf="
                  row.value.highestScoreAchieved !== null &&
                  !classOverview.readonly
                "
                [class.below-benchmark]="
                  row.value.highestScoreAchieved <= benchmarkScores.atRisk.max
                "
                [routerLink]="[
                  'student',
                  row.data.id,
                  'review',
                  row.value.highesScoreAttemptId
                ]"
                title="Edit Test Attempt"
                ><i class="pi pi-pencil"></i
              ></a>
              <a
                *ngIf="
                  row.value.highestScoreAchieved === null &&
                  !classOverview.readonly
                "
                [routerLink]="['student', row.data.id, 'new', row.value.testId]"
                title="Add Test Attempt"
                ><i class="pi pi-plus"></i
              ></a>
            </div>
          </div>
        </dxi-column>

        <dxo-summary *ngIf="classOverview.unitScores">
          <dxi-total-item
            column="name"
            cssClass="benchmark-text"
            displayFormat="% of class at or above benchmark ({{
              benchmarkScores.onTrack.min
            }})"
          ></dxi-total-item>
          <ng-container>
            <dxi-total-item
              *ngFor="let def of testDefinitions"
              column="{{ def.id }}"
              cssClass="benchmark-values"
              displayFormat="{{
                classOverview.unitScores.aboveBenchmark[def.id]
              }}/{{
                classOverview.unitScores.studentsWithResponsesCount[def.id]
              }}"
            ></dxi-total-item>
          </ng-container>
          <dxi-total-item
            *ngFor="let def of testDefinitions"
            column="{{ def.id }}"
            cssClass="benchmark-values average-score {{
              classOverview.unitScores.aboveBenchPercentage[def.id] >=
              benchmarkScores.onTrack.min
                ? 'above'
                : 'below'
            }}"
            displayFormat="{{
              classOverview.unitScores.aboveBenchPercentage[def.id]
            }}%"
          ></dxi-total-item>
        </dxo-summary>

        <div *dxTemplate="let row of 'studentNameTemplate'">
          <a
            [routerLink]="['/reports/student', classId, row.data.id]"
            trackClick="UTTViewStudent"
          >
            {{ row.value }}
          </a>
        </div>
      </dx-data-grid>
      <p class="p-text-secondary">
        <em
          >* Indicates multiple test scores for that unit. Click to view all
          attempts.</em
        >
      </p>
      <div class="card mb-0">
        <h3>Helpful Hints</h3>
        <p class="p-text-secondary mb-0">
          <strong>View Student:</strong> Click the name of a student to view
          their individual unit test tracker.
        </p>
        <p class="p-text-secondary">
          <strong>Sorting:</strong> To sort by multiple column headings, use
          Shift + click on the headings you'd like to sort.
        </p>
      </div>

      <p-dialog
        *ngIf="selectedStudent"
        [(visible)]="showScoresModal"
        [dismissableMask]="true"
        [draggable]="false"
        [modal]="true"
        [resizable]="false"
        header="Unit Test Scores for {{ selectedStudent.name }}"
      >
        <p-table [value]="selectedStudent.scoreAttempts" id="multiScoreTable">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col">Attempt Number</th>
              <th scope="col">Date Taken</th>
              <th scope="col">Score</th>
              <th scope="col"></th>
            </tr>
          </ng-template>
          <ng-template let-data pTemplate="body">
            <tr>
              <td>{{ data.attemptNumber }}</td>
              <td>{{ data.dateAttempted | date : 'shortDate' }}</td>
              <td>{{ data.score }}</td>
              <td>
                <a
                  [routerLink]="[
                    'student',
                    selectedStudent.id,
                    'review',
                    data.attemptId
                  ]"
                  >View</a
                >
              </td>
            </tr>
          </ng-template>
        </p-table>
        <ng-template pTemplate="footer">
          <button
            (click)="toggleScoresModal()"
            class="p-button-outlined"
            label="Close"
            pButton
            type="button"
          ></button>
        </ng-template>
      </p-dialog>
    </div>
    <div class="text-right">
      <button
        (click)="deleteClass()"
        class="wlt-btn btn-danger"
        data-automationId="delete_class_button"
        *ngIf="!classOverview.readonly && isPersonalTenant"
      >
        Delete Class
      </button>
    </div>
  </div>
  <app-search-students
    *ngIf="addingExistingStudent"
    (selectStudent)="selectStudent($event)"
    [existingStudentIds]="existingStudentIds"
    (cancelAddExisting)="cancelAddExistingStudent()"
  >
  </app-search-students>
</ng-container>
